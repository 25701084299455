import React from "react"
import { Button, Icon, IconButton, Typography, makeStyles } from "@material-ui/core"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import GenericImage from "../../../components_v1/Structure/DisplayData/GenericImage"
import './textStyles.css'

const styles = makeStyles(theme => ({
    dot:{
        width:10,
        height:10,
        borderRadius:'50%',
        background:theme.palette.primary.main,
        cursor:'pointer',
        display:'inline-block',
        margin:'0px 5px',
        top:100
    },
    dot_selected:{
        background:theme.palette.primary.main
    },
    dots_container:{
        marginTop:32
    },
    img:{
        borderRadius:20,
        width:'100%',
        height:'100%',
        objectFit:'cover'
    },
    img_container:{
        position:'absolute',
        width:'100%',
        height:'100%',
        top:0,
        left:0
    },
    container:{
        position:'relative',
        //paddingTop:'66%',
        width:'100%',
        
        //maxWidth:1000,
        //margin:'auto',
        //background:'red'
    },
    box:{
        width:'100%',
        maxWidth:1000,
        margin:'auto',
        minWidth:700
    },
    icon_button:{
        position:'absolute',
        right:20,
        zIndex:10,
        background:'white',
        color:theme.palette.secondary.main,
        '&:hover':{
            background:'white',
        }
    },
    go_button:{
        background:theme.palette.secondary.main,
        borderRadius:24,
        padding:'6px 16px',
        position:'absolute',
        zIndex:10,
        bottom:20,
        right:20,
        color:'white',
        textTransform:'none'
    },
    mask:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        background:'rgba(12, 37, 78, 0.58)',
        borderRadius:20,
        zIndex:5
    },
    subtitle:{
        whiteSpace: "initial",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        color:'white',
        fontSize:'1.8rem',
    },
    text_container:{
        position:'absolute',
        bottom:20,
        //background:'red',
        zIndex:7,
        width:'100%',
        padding:'12px 24px 20px',
        textAlign:'left',
        //boxSizing:'border-box'

    },
    subtitle_container:{
        width:'100%', 
        maxHeight:'86.38px', 
        //background:'green', 
        textOverflow:'ellipsis',
        fontFamily:theme.typography.fontFamily,
        fontSize:'1.8rem',
        overflow:'hidden',
        color:'white'

    },
    title:{
        whiteSpace: "initial",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        color:'white',
        fontSize:'2.4rem',
    }
}))

const Carrousel2 = ({banners, permissions, actions}) => {

    const classes = styles()

    let banner_permission_edit = false
    let banner_permission_add = false
    let banner_link = null
 

    if(permissions && permissions.length > 0){
        const banner_edit = permissions.find(el => el.permission_module_id === 75 && el.permission_type_id >= 3)
        const banner_add = permissions.find(el => el.permission_module_id === 75 && el.permission_type_id >= 4)
        if(banner_edit !== undefined) banner_permission_edit = true
        if(banner_add !== undefined) banner_permission_add = true
    }

         

    return(
        <div className={classes.box}>
            {banners && banners.length > 0 ?
                <Carousel 
                autoPlay={true}  
                interval={3000}
                showArrows={false}
                infiniteLoop={true}
                transitionTime={1000}
                autoFocus={true}
                selectedItem={0}
                showStatus={false}
                //showThumbs={true}
                //showIndicators={false}
                /* renderIndicator={(onClickHandler, isSelected, index, label) => {
                    const defStyle = { marginLeft: 20, color: "red", cursor: "pointer", width:100 };
                    const style = isSelected
                      ? { ...defStyle, color: "red" }
                      : { ...defStyle };
                    return (
                      <div className={classes.dot}>
                      </div>
                    );
                  }} */
                /* onChange={onChange} onClickItem={onClickItem} onClickThumb={onClickThumb} */>
                {banners.map((item, ind) => {
                    return(
                        <div className={classes.container}>
                            {banner_permission_add ? <IconButton onClick={()=>actions.onChangeModalStatus('add_banner', true)} className={classes.icon_button} style={{top:20}} ><Icon>add</Icon></IconButton> : null}
                            {banner_permission_edit ? <IconButton onClick={()=>actions.onEditBanner(item)} className={classes.icon_button} style={{top:90}} ><Icon>edit</Icon></IconButton> : null}
                            {item.link !== null ? <Button href={item.link} target="_blank" className={classes.go_button} endIcon={<Icon>navigate_next</Icon>} >Visitar</Button> : null} 
                            <div className={classes.text_container}>                           
                                <Typography className={classes.title} >{item?.title}</Typography>
                                <Typography className={classes.subtitle} >{item?.subtitle}</Typography> 
                            </div>
                            <div className={classes.mask} ></div> 
                            <GenericImage ratio='4/3' borderRadius='20px' src={item.web_image} /> 
                        </div>
                    )
                })}
            </Carousel> : null}
        </div>
    )
}

export default Carrousel2